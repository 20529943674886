<template>
  <div>

    <CCard>
      <CCardBody>
        <h3>Transaction Report </h3>
        <CAlert :color="alertType" v-if="message">
          {{ message }}
        </CAlert>
        <div v-if="isLoadingRequired === false">
<!--           <CRow>
            <CCol sm="5" v-if="user?.hasGlobalRights == true">
              <CSelect label="Partners" :value.sync="partnerId" :plain="true" :options="partners"
                placeholder="Select a partner">
              </CSelect>
            </CCol>
          </CRow> -->
          <CRow>
            <CCol sm="5">
              <label>From</label>
<ejs-datepicker v-model="from" :format="$dateFormatInput.split(' ')[0]"></ejs-datepicker>
            </CCol>
          </CRow>
          <CRow>
            <CCol sm="5">
              <label>To</label>
<ejs-datepicker v-model="to" :format="$dateFormatInput.split(' ')[0]"></ejs-datepicker>

            </CCol>
          </CRow>
          <CRow>
            <CCol sm="5" style="margin-top:20px">
              <CButton color="primary" @click="generateTransaction" class="btn-lg">
                <CIcon name="cil-save" /> Generate your report
              </CButton>
            </CCol>
          </CRow>

        </div>
        <CSpinner style="display: block; position: fixed; top: 50%; left: 50%" v-if="isLoadingRequired === true"
          color="primary" />

      </CCardBody>
    </CCard>


  </div>
</template>

<script>
import axios from "axios";
import { DatePickerComponent } from '@syncfusion/ej2-vue-calendars';
export default {
  name: "TransactionReports",
  components: {
    'ejs-datepicker': DatePickerComponent,
  },
  data: () => {
    return {
      message: null,
      partnerId: "00000000-0000-0000-0000-000000000000",
      partners: [],
      alertType: "danger",
      from: null,
      to: null,
      user: null,
      isLoadingRequired: false
    }
  },
  methods: {
    generateTransaction() {
      let self = this;
      self.isLoadingRequired = true;

      if (self.from == null || self.from == undefined) {
        self.message = "From date is mandatory";
        self.isLoadingRequired = false;
        return;
      }
      if (this.to == null || this.to == undefined) {
        self.message = "End date is mandatory";
        self.isLoadingRequired = false;
        return;
      }

      let url = this.$apiAdress + "/v1/Reports/list/" + this.moment(this.from).format("yyyy-MM-DD") + "/" + this.moment(this.to).format("yyyy-MM-DD");

      axios.get(url)
        .then(function (response) {
          let data = response.data;
          let csv = "\uFEFF"; // Byte-Order Mark (BOM) for UTF-8
          csv += "Created,Travel Date Start,Travel Date End,Currency,Booking No,Payment Method,Sold By,VAT Number,Country,Customer,Company,VAT Number,Country,Total Price Excl VAT,Total Price,VAT %,VAT Sum,Own Sales Excl VAT,Own Sales VAT %,Own Sales VAT,Partner Name,Partner VAT Number,Partner Country,Partner Sales excl VAT,Partner Sales VAT %,Partner Sales VAT,Platform Commission,Platform Commission VAT %,Platform Commission VAT, Status\r\n";

          data.forEach((row) => {
            csv += `${row.bookingDate},${row.travelDateStart},${row.travelDateEnd},${row.currency},${row.bookingNo},${row.paymentMethod},${row.soldBy},${row.soldByVATNumber},${row.country},${row.customer},${row.company},${row.companyVATNumber},${row.customerCountry},${row.totalPriceExclVAT},${row.totalPrice},${row.vatPercentage},${row.vatSum},${row.ownSalesExclVAT},${row.ownSalesExclVATPercentage},${row.ownSalesVAT},${row.partnerName},${row.partnerVATNumber},${row.partnerCountry},${row.partnerSalesExclVAT},${row.partnerSalesVATPercentage},${row.partnerSalesVAT},${row.platformComission},${row.platformComissionVATPercentage},${row.platformComissionVAT},${row.status}\r\n`;
          });

          const anchor = document.createElement('a');
          anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
          anchor.target = '_blank';
          anchor.download = 'bookingsReport.csv';
          anchor.click();
          self.isLoadingRequired = false;
        })
        .catch(function (error) {
          console.error("Error generating CSV:", error);
          self.isLoadingRequired = false;
        });
    },
    getPartners() {
      let self = this;
      axios
        .get(this.$apiAdress + "/v1/Partner")
        .then(function (response) {

          response.data.map(function (value, key) {
            value.value = value.id;
            value.label = value.name;
            self.partners.push(value);
          });
        })
        .catch(function (error) {
          this.message = error;
          console.log(error);
        });

      var user = localStorage.getItem("user");
      if (user != null) {
        self.user = JSON.parse(localStorage.getItem("user"));
      }
    }
  },
  mounted: function () {
    this.getPartners();
  }
}
</script>